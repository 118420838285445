import React from 'react'

import Layout from '../components/layout'

import TraktorguideImage from '../images/traktorguide.jpg'
import TraktorguidePdf from '../images/traktorguide.pdf'

const Traktorguiden = () => (
  <Layout articlelevel={100}>
    <>
      <div className="cell imageWrapper imageWrapper--traktor">
        <img
          width="600"
          height="966"
          src="/assets/traktorguide.jpg"
          alt="Traktorguide"
          srcSet={TraktorguideImage}
          sizes="(max-width: 600px) 100vw, 600px"
        />
      </div>
      <div className="cell contentWrapper">
        <div className="content whiteText">
          <section>
            <h1>Varsågod! Ladda ner specialutgåvan och börja läs redan nu.</h1>
            <a class="button" target="_blank" rel="noreferrer" href={TraktorguidePdf}>
              Hämta specialutgåvan!
            </a>
          </section>
        </div>
      </div>
    </>
  </Layout>
)

export default Traktorguiden
